@use '@wizbii-components/styles/src/colors/colors' as colors;
@use '@wizbii-components/styles/src/breakpoints' as br;

[class^='gdpr_overlay'] {
  transform: none !important;

  & > * {
    transform: none !important;
  }
}

[class^='gdpr_banner__cookie_icon'] {
  font-size: 1rem;
}

[class^='gdpr_banner__title'] {
  font-size: 1rem !important;
  font-weight: 600 !important;
}

[class^='gdpr_banner__text'] {
  font-weight: 300 !important;
}

[class^='gdpr_switch__button'] {
  background: colors.$wzb-purple !important;
}

[class*='activated'] [class^='gdpr_switch__button'] {
  background-color: colors.$wzb-purple !important;
}
[class*='activated'] [class^='gdpr_switch__label'] {
  color: colors.$wzb-purple !important;
}

[class^='gdpr_banner__accept'] {
  @include br.handset {
    &:hover {
      background: colors.$wzb-purple !important;
      color: white !important;
    }
  }
}

[class^='gdpr_dialogbox__title'] {
  text-align: center;
}
