/* stylelint-disable unit-disallowed-list */
@use '@wizbii-components/styles/src/colors/colors' as colors;

// https://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
}

.visually-hidden {
  @include visually-hidden;
}

.error {
  position: absolute;
  right: 0;
  bottom: 0;
  color: colors.$wzb-red;
  font-size: 0.625rem;
  font-weight: 500;
  text-align: center;

  &--form {
    margin: 0;
    color: colors.$wzb-red;
    font-size: 0.625rem;
    font-weight: 500;
    text-align: center;
  }
}
