@use '@wizbii-components/styles/src/colors/colors' as colors;
@use '@wizbii-components/styles/src/breakpoints' as br;

.legal-article {
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    background: url('https://storage.googleapis.com/wizbii-images/legal/mobile-rainbow-background.png') no-repeat
      initial top;
    width: 61.625rem;
    height: 50.5625rem;
    z-index: -1;
  }

  @include br.notHandset {
    &::after {
      background-image: url('https://storage.googleapis.com/wizbii-images/legal/rainbow.svg');
    }
  }

  // stylelint-disable-next-line selector-no-qualifying-type
  h2[id] {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.4;
    padding-top: 7.75rem;
    margin-top: -5.75rem;

    @include br.notHandset {
      font-size: 1.5rem;
    }
  }

  .cms-v3-article {
    &__summary {
      &__title {
        font-weight: bold;
        font-size: 1.25rem;
        line-height: 1.3;
        color: colors.$wzb-black;
        margin: 0 auto 1.875rem;
      }

      &__item {
        margin-bottom: 1rem;
        font-weight: 600;
        font-size: 0.875rem;
        line-height: 1.43;
        color: colors.$wzb-black;
        width: fit-content;

        a:hover {
          text-decoration: none;
        }
      }

      &__menu {
        list-style-type: none;
        margin-bottom: 4.125rem;
        padding: 0;
      }
    }

    @include br.notHandset {
      &__summary {
        &__title {
          font-size: 1.5rem;
        }

        &__item {
          font-size: 1.25rem;

          &:hover {
            color: colors.$wzb-purple;
          }
        }

        &__menu {
          margin-bottom: 3.75rem;
          width: fit-content;
        }
      }
    }
  }
}
