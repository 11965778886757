/* stylelint-disable value-keyword-case */
@use 'sass:map' as map;
/* stylelint-disable no-invalid-position-at-import-rule */
@use '@angular/material' as mat;
@use '@angular/cdk';

@use '@wizbii-components/angular-styles/src/theming' as theming;

// always include only once per project
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
// @include mat.all-legacy-component-typographies;
// @include mat.legacy-core();

// Define the palette using : http://mcg.mbitson.com
$md-wizbii-purple: (
  50: #f1f0ff,
  100: #dbdaff,
  200: #c3c1ff,
  300: #aba8ff,
  400: #9995ff,
  500: #8782ff,
  600: #7f7aff,
  700: #746fff,
  800: #6a65ff,
  900: #5752ff,
  A100: #fff,
  A200: #fff,
  A400: #f8f7ff,
  A700: #dfdeff,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #fff,
    900: #fff,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$md-wizbii-green: (
  50: #e8fef8,
  100: #c5fdee,
  200: #9efce3,
  300: #77fbd7,
  400: #5afacf,
  500: #3df9c6,
  600: #37f8c0,
  700: #2ff7b9,
  800: #27f6b1,
  900: #1af5a4,
  A100: #fff,
  A200: #f4fffb,
  A400: #c1ffe7,
  A700: #a7ffdd,
  contrast: (
    50: #000,
    100: #000,
    200: #000,
    300: #000,
    400: #000,
    500: #000,
    600: #000,
    700: #000,
    800: #000,
    900: #000,
    A100: #000,
    A200: #000,
    A400: #000,
    A700: #000,
  ),
);
$md-invalid-red: (
  50: #ffebec,
  100: #ffcecf,
  200: #ffaeb0,
  300: #ff8d90,
  400: #ff7478,
  500: #ff5c60,
  600: #ff5458,
  700: #ff4a4e,
  800: #ff4144,
  900: #ff3033,
  A100: white,
  A200: white,
  A400: #ffdbdc,
  A700: #ffc2c3,
  contrast: (
    50: black,
    100: black,
    200: black,
    300: black,
    400: black,
    500: black,
    600: black,
    700: black,
    800: white,
    900: white,
    A100: black,
    A200: black,
    A400: black,
    A700: black,
  ),
);

@include theming.wzbTheming($md-wizbii-purple, $md-wizbii-green, $md-invalid-red);
